import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import SwiperCore, { Navigation, Autoplay } from "swiper";

import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";
import "swiper/swiper-bundle.min.css";
// import Thumbnail from "../../../../../assets/images/thumbnail.svg";

import propTypes from "prop-types";

SwiperCore.use([Navigation]);
const Advertisement = ({
  slidesPerView,
  data,
  isHomePageAdvertisements = false,
}) => {
  const prevRef = useRef();
  const nextRef = useRef();
  if (isHomePageAdvertisements) {
    return (
      <div className="cr-adv relative h-[130px] mt-12 w-[95%] mx-auto overflow-hidden" >
        <div className="carousel-primary">
          {data?.concat(data)?.map((item, i) => (
            <iframe
              src={`Advertisement.html?id=${item}`}
              key={`${item}| ${i}`}
              className="max-h-[120px] max-w-[270px]"
            ></iframe>
          ))}
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="font-ibm mb-10 ">
        <section className="max-w-full z-40 mt-5 overflow-x-visible">
          <Swiper
            autoplay={{
              delay: 1500,
              disableOnInteraction: true,
            }}
            loop={true}
            modules={[Navigation, Autoplay]}
            navigation={false}
            spaceBetween={0}
            onInit={(swiper) => {
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }}
            slidesPerView={slidesPerView}
          >
            {data?.map((item) => (
              <SwiperSlide key={item.id}>
                <iframe src={`Advertisement.html?id=${item}`}></iframe>
              </SwiperSlide>
            ))}
          </Swiper>
        </section>
      </div>
    </>
  );
};

Advertisement.propTypes = {
  width: propTypes.string,
  height: propTypes.string,
  slidesPerView: propTypes.number,
  data: propTypes.array,
  isHomePageAdvertisements: propTypes.bool,
};

export default Advertisement;
